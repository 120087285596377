import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Icon8 from "../../../static/images/partners/members/aws.svg"
import Icon10 from "../../../static/images/partners/members/google.svg"
import { FaArrowCircleRight } from "react-icons/fa"

const PartnerAzure = () => {
  const data = useStaticQuery(graphql`
    query Images_partnerazure {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Especialistas en Cloud y Microsoft Azure Partner | Qualoom</title>
        <meta name="title" content="Especialistas en Cloud y Microsoft Azure Partner | Qualoom"/>
        <meta name="description" content="Advanced Consulting Partner de Amazon Web Services. Ofrecemos implantación y mantenimiento de infraestructuras Cloud y Microsoft Azure ¡Infórmate!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/partner-microsoft-azure/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/partner-microsoft-azure/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/partner-microsoft-azure/"/>
        <meta property="og:title" content="Especialistas en Cloud y Microsoft Azure Partner | Qualoom"/>
        <meta property="og:description" content="Advanced Consulting Partner de Amazon Web Services. Ofrecemos implantación y mantenimiento de infraestructuras Cloud y Microsoft Azure ¡Infórmate!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/partner-microsoft-azure/"/>
        <meta property="twitter:title" content="Especialistas en Cloud y Microsoft Azure Partner | Qualoom"/>
        <meta property="twitter:description" content="Advanced Consulting Partner de Amazon Web Services. Ofrecemos implantación y mantenimiento de infraestructuras Cloud y Microsoft Azure ¡Infórmate!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/partner-microsoft-azure/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}},
              {"@type":"ListItem", "position":"3", "name":"Microsoft Azure Partner", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/partner-microsoft-azure/", "name": "Microsoft Azure Partner"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Microsoft Azure Partner</h1>
              <p>Partner de Microsoft desde hace más de 10 años y participante activo en proceso de migración a la nube, migración de correo electrónico y reventa de licencias corporativas, hacen que cientos de clientes confíen en nosotros cuando este es el Cloud considerado como más adecuado en función de la naturaleza del servicio de la organización.​</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué es Microsoft Azure?</h2><hr></hr>
          <div className="section-main">
            <div className="section-img azure"></div>
            <div className="section-text">
              <p><b>Es la plataforma Cloud ofrecida por Microsoft compuesta por más de 200 productos y servicios diseñados para implementar nuevas soluciones</b> que permitan resolver las dificultades actuales de una organización.</p>
            </div>
          </div><br/>
          <h3>Infraestructuras Cloud en otras plataformas</h3>
          <div className="cloud-platform bg-white">
            <a href="/cloud-infraestructura/partner-advanced-aws/" className="cloud-platform-icon"><img src={Icon8} alt="Partner Advanced AWS"></img><p>Partner Advanced AWS</p><div><span>Descubre más</span><FaArrowCircleRight size={15}/></div></a>
            <a href="/cloud-infraestructura/partner-google-cloud/" className="cloud-platform-icon"><img src={Icon10} alt="Partner Google Cloud"></img><p>Partner Google Cloud<br/>Platform y Reseller</p><div><span>Descubre más</span><FaArrowCircleRight size={15}/></div></a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PartnerAzure